@import '../../styles/variables.scss';

.searchContainer {
    background-color: $couleur-neutre-regular;
    padding: 0.5rem;
    display: flex;
    justify-content: center;

    [data-component="SearchBox"] {
        max-width: 600px;
    }
}