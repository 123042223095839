@import '../../styles/variables.scss';

.header {
    background-color: $couleur-primaire-regular;
    display: flex;
    height: 75px;
    justify-content: center;
    width: 100%;

    .headerContent {
        align-items: center;
        display: flex;
        padding: 1rem;
        width: min(1152px, 100%);

        .logoLinkButton {
            margin-right: 20px;

            @media #{$mq-large-and-over} {
                margin-right: 140px;
            }

            .logoLinkButtonLink {
                display: flex;
                align-items: center;
                position: relative;

                img {
                    width: 144px;
                }
            }
        }

        .navItemsLevel1Container {
            flex: auto;
            display: flex;
            justify-content: center;

            @media #{$mq-large-and-over} {
                gap: 30px;
                justify-content: left;
            }

            .navButton {
                font-size: 24px;
                white-space: nowrap;
            }
        }
    }
}

.navPanel {
    background-color: $couleur-primaire-regular;
    box-shadow: 0 1px 5px 0 #005b5f;
    display: flex;
    left: 50%;
    padding: 1rem;
    padding-bottom: 2rem;
    position: absolute;
    transform: translateX(-50%);
    width: min(100%, 920px);
    z-index: 30;

    .navItemsLevel2Container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .level1Children {
            transition: all 0.3s ease;

            em {
                top: -3px;
            }

            &:focus:not(.selected),
            &:hover:not(.selected) {
                em {
                    color: white;
                }
            }
        }

        .allButton {
            margin-top: 1rem;
            margin-left: 1rem;
        }

        .navButton {
            font-size: 20px;
            margin-bottom: 0;
        }
    }

    .navItemsLevel3Container {
        align-items: flex-start;
        border-left: 2px solid white;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: 2rem;
        max-height: 350px;
        padding-left: 2rem;

        .navButton {
            margin-right: 3rem;
        }

        .level2Children {
            em {
                top: -3px;
            }

            &:focus:not(.selected),
            &:hover:not(.selected) {
                em {
                    color: white;
                }
            }
        }
    }

    .navButton {
        margin-bottom: 0;
    }
}

.navButton {
    color: white;
    font-family: 'futura-pt', sans-serif;
    font-weight: 500;
    height: 42px;
    line-height: 42px;
    padding: 0 0.8em;

    span {
        display: block;
        height: 42px;
        line-height: 42px;
        position: relative;
        top: 0;

        &::after {
            background-color: transparent;
            bottom: 5px;
            content: "";
            display: block;
            height: 3px;
            left: 0;
            position: absolute;
            transition: all 0.3s ease;
            width: 0;
        }
    }

    &.level2Children {
        height: 38px;
        line-height: 38px;

        span {
            height: 38px;
            line-height: 38px;
        }
    }

    &:focus:not(.selected),
    &:hover:not(.selected) {
        span {
            color: white;

            &::after {
                background-color: white;
                width: 100%;
            }
        }
    }

    &.selected {
        background-color: white;
        color: $couleur-primaire-regular;
    }
}