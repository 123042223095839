@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.me {
    background-color: $couleur-base-tq;

    :global(.body) {
        align-items: right;
        height: 30px;
    }

    .banner {
        display: flex;
        justify-content: center;

        @media #{$mq-medium-and-over} {
            justify-content: flex-end;
        }

        a {
            align-content: center;
            align-items: center;
            color: $couleur-base-blanc;
            display: inline-flex;
            height: 100%;
            justify-content: flex-end;

            &:focus,
            &:hover {
                .linkIcon {
                    background-color: $couleur-base-blanc;
                    color: $couleur-base-tq;
                    fill: $couleur-base-tq;
                }
            }

            .linkIcon {
                color: $couleur-base-blanc;
                fill: $couleur-base-blanc;
                margin-left: 5px;
                transition: all 0.2s ease-in-out;
            }
        }
    }
}
