@import '../../../styles/variables.scss';

.me {
    &.loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
    }

    ul {
        list-style: none;
        margin-left: 0;
    }

    li {
        padding: 0;

        button {
            width: 100%;
            display: block;

            :global(.btnLabel) {
                align-items: center;
                border: 1px solid $couleur-neutre-regular;
                display: flex;
                position: relative;
                text-align: left;
                width: 100%;

                &:focus,
                &:hover {
                    border-color: $couleur-neutre-dark;
                }

                .thumbnail {
                    margin-right: 10px;
                    width: 58px;

                    @media #{$mq-medium-and-over} {
                        margin-right: 1rem;
                        width: 64px;
                    }
                }

                .description {
                    color: $couleur-text-regular;
                    display: block;
                    font-size: $font-size-small;
                    margin-bottom: 2px;
                    padding-right: 10px;
                }

                .nom {
                    display: block;
                    line-height: 1.2rem;
                    padding-right: 10px;
                }
            }
        }
    }
}
