@import "../../styles/variables.scss";
$publiciteLabelHeight: 25px;

@mixin label {
    position: absolute;
    width: 100%;
    content: "publicité";
    font-size: 11px;
    line-height: 11px;
    font-family: arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $publiciteLabelHeight;
    text-transform: uppercase;
    text-align: center;
    color: $couleur-text-dark;
}

@mixin labelTop {
    position: relative;

    &:before {
        @include label;
        background: $couleur-neutre-light;
        top: 0;
    }
}

.pub {
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    [data-m32-dynamic-ad] {
        padding-top: $publiciteLabelHeight;

        &:not([style="display: none;"]) {
            @include labelTop;
        }
    }

    &.mobile-leaderboard {
        width: 100%;
        min-height: calc(50px + #{$publiciteLabelHeight});
        height: calc(100% + #{$publiciteLabelHeight});
    }

    &.leaderboard {
        width: 100%;
        min-height: calc(90px + #{$publiciteLabelHeight});
        height: calc(100% + #{$publiciteLabelHeight});
    }

    &.leaderboard-billboard {
        width: min-content;
        height: calc(100% + #{$publiciteLabelHeight});
        margin-top: 2rem;
        max-width: 100%;
        overflow: hidden;
    }

    &.medium-rectangle {
        width: 300px;
        height: calc(250px + #{$publiciteLabelHeight});
    }

    &.medium-rectangle-half-page {
        width: 300px;
        height: calc(600px + #{$publiciteLabelHeight});
        margin: 0 auto;
    }

    @media print {
        display: none;
    }
}