@import '../../styles/variables.scss';

.me {
    background-color: $couleur-neutre-regular;
    padding-bottom: 30px;
    padding-top: 30px;

    @media print {
        display: none;
    }

    .body {
        padding: 0 28px;

        @media #{$mq-body-and-over} {
            padding: 0 0.5rem;
        }

        > div {
            margin-bottom: 1.5rem;
        }

        > .last:last-child {
            border-top: 2px solid $couleur-primaire-regular;
            margin-bottom: 0;
            padding-top: 1rem;
        }

        @media #{$mq-medium-and-over} {
            display: flex;
            justify-content: space-between;
            gap: 0.5rem;

            > div {
                flex-basis: 0;
                flex-grow: 1;
                margin: 0rem;
            }

            > .last:last-child {
                border-left: 2px solid $couleur-primaire-regular;
                border-top: 0 none;
                padding-left: 1rem;
                padding-top: 0;
            }
        }

        h5 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    ul {
        margin: 0;
        list-style: none;
    }

    a {
        text-decoration: none;
    }

    .logoDisplay {
        background-color: transparent;
        background-image: url(../../styles/fonts/fichiers_source/logo_rectangle.svg);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        height: 40px;
        margin-bottom: 10px;
    }

    .itemsLink {
        list-style: none;
        margin-bottom: 15px;

        .itemList {
            display: inline-block;
            margin-right: 9px;
            margin-bottom: 0;
        }
    }
}
