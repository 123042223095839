@import '../../styles/variables.scss';

.grilleDebugger {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: $grid-column-gap;

    > div {
        border: 1px solid #333;
        height: 100%;
        background: rgba(255, 255, 255, 0.4);
    }
}
