@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.me {
    @include bouton;
    left: 1%;
    position: absolute;
    z-index: 1000;
    transform: translateY(-200%);

    background-color: $couleur-primaire-regular;
    border: 1px solid $couleur-primaire-regular;
    color: $couleur-base-blanc;

    &:focus,
    &:hover {
        transform: translateY(0);
    }

    @media #{$mq-small-only} {
        display: none;
    }

    @media print {
        display: none;
    }
}
