@import "../../styles/variables.scss";

.storybook {
    max-width: 500px;
}

.image {
    width: 100%;
    height: 100%;
}

// Avoids layout shifts and makes scrollToTop consistent
.format1x1 {
    aspect-ratio: 1 / 1;
    background-color: #eeeeee;
}

.format2x3 {
    aspect-ratio: 2 / 3;
    background-color: #eeeeee;
}

.format16x9 {
    aspect-ratio: 16 / 9;
    background-color: #eeeeee;
}
