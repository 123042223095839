@import '../../styles/variables.scss';

.me {
    display: inline-block;
    position: relative;
    width: 100%;

    @media print {
        display: none;
    }

    form {
        margin: 0;
        padding: 0;
    }

    input {
        border: 1px solid $couleur-neutre-regular;
        font-size: 1rem;
        line-height: 2;
        padding: 0rem 2.25rem 0rem 0.55rem;
        width: calc(100% - 2.75rem - 2px);

        @media #{$mq-medium-and-over} {
            font-size: 1rem;
            line-height: 2;
            padding-bottom: 0.25rem;
            padding-top: 0.25rem;
            padding-left: 0.75rem;
            width: calc(100% - 3rem - 2px);
        }

        &::placeholder {
            font-style: italic;
            font-size: 0.75rem;
        }
    }

    .loupe {
        position: absolute;
        right: 0.3rem;
        top: 0.5rem;

        @media #{$mq-medium-and-over} {
            right: 0.25rem;
            top: 0.5rem;
        }

        span {
            font-size: 1.2rem;
            @media #{$mq-medium-and-over} {
                font-size: 1.6rem;
            }
        }
    }

    .pannel {
        background-color: $couleur-base-blanc;
        border: 1px solid $couleur-neutre-regular;
        -webkit-box-shadow: 0px 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0px 0 0 0 rgba(0, 0, 0, 0);
        left: 0;
        margin-top: -1px;
        min-height: 250px;
        padding: 0.5rem;
        position: absolute;
        right: 0;
        text-align: left;
        transform-origin: top right;
        transform: scaleY(0);
        transition: transform 200ms, box-shadow 300ms;
        z-index: 9;

        &.isOpen {
            transform: scaleY(1);
            -webkit-box-shadow: 0px 10px 18px -5px rgba(0, 0, 0, 0.7),
                0px 0px 8px -2px rgba(0, 0, 0, 0);
            box-shadow: 0px 10px 18px -5px rgba(0, 0, 0, 0.7), 0px 0px 8px -2px rgba(0, 0, 0, 0);
        }

        .buttonResult {
            display: block;
            margin: 0.5rem auto 0;
            max-width: 300px;
            width: 100%;
        }
    }
}
