@import '../../styles/variables.scss';

.me {
    align-items: center;
    border-radius: 2rem;
    color: #fff;
    display: inline-flex;
    font-size: $font-size-normal;
    font-weight: 500;
    justify-content: center;
    line-height: $font-size-l;
    padding: 0.4rem 0.8rem;
    transition: all 0.2s ease-in-out;
    vertical-align: middle;

    &.xs {
        font-size: $font-size-tiny;
        line-height: $font-size-xl;
        padding: 0.05rem 0.5rem;
    }

    &.sm {
        font-size: $font-size-small;
        padding: 0.3rem 0.7rem;
    }

    &.primary {
        background-color: $couleur-primaire-dark;
        border: 1px solid $couleur-primaire-dark;
        color: $couleur-base-blanc;

        &:hover {
            background-color: $couleur-primaire-regular;
            border-color: $couleur-primaire-regular;
            text-decoration: none;
        }

        &.disabled {
            background-color: $couleur-primaire-dark !important;
            border-color: $couleur-primaire-dark;
            color: $couleur-base-blanc !important;
        }
    }

    &.light {
        background-color: $couleur-neutre-regular;
        border: 1px solid $couleur-neutre-regular;
        color: $couleur-primaire-regular;

        &:hover {
            color: $couleur-base-noir;
            text-decoration: none;
        }
    }

    &.dark {
        background-color: $couleur-primaire-dark;
        border: 1px solid $couleur-primaire-dark;
        color: $couleur-base-blanc;

        &:hover {
            background-color: $couleur-base-blanc;
            border: 1px solid $couleur-base-noir;
            color: $couleur-base-noir;
            text-decoration: none;
        }

        &.link {
            color: $couleur-base-noir;

            &:hover {
                color: $couleur-primaire-dark;
            }
        }
    }

    &.danger {
        border: 1px solid $couleur-alerte-rouge;
        color: $couleur-alerte-rouge;

        &:hover {
            color: $couleur-primaire-regular;
            text-decoration: none;
        }
    }

    &.carre {
        border-radius: 5px;
    }

    &.rond {
        align-items: center;
        display: inline-flex;
        height: 2.1rem;
        justify-content: center;
        position: relative;
        width: 2.1rem;
    }

    &.disabled {
        background-color: $couleur-neutre-regular !important;
        border-color: $couleur-neutre-regular !important;
        color: #333 !important;
        cursor: default;
        pointer-events: none;
    }

    &.invert {
        background-color: $couleur-primaire-dark;
        border: 1px solid $couleur-primaire-dark;
        color: $couleur-base-blanc;

        &:hover {
            background-color: #fff;
            color: $couleur-base-noir;
            text-decoration: none;
        }
    }

    &.border {
        background-color: white;
        border: 1px solid $couleur-base-noir;
        color: $couleur-base-noir;

        &:hover {
            background-color: $couleur-primaire-dark;
            border: 1px solid $couleur-primaire-dark;
            color: #fff;
            text-decoration: none;
        }
    }

    button {
        color: $couleur-primaire-dark;

        &:hover {
            color: $couleur-primaire-regular;
        }
    }
}

// Pas dans .me
.meGlobal {
    .btnLabel {
        display: inline-flex;
    }

    .count {
        font-weight: 300;
        opacity: 0.7;
        padding-left: 0.3rem;
    }

    &:hover {
        cursor: pointer;
    }

    &.link {
        text-decoration: none;

        &:hover .btnLabel {
            text-decoration: underline;
        }

        .icon {
            font-size: $font-size-normal;
            top: 2px;

            &.withText {
                padding-right: 10px;

                &.iconRight {
                    padding-right: 0;
                    padding-left: 10px;

                    &.fleche-right {
                        padding-left: 0;
                        padding-right: 10px;
                    }
                }
            }
        }
    }

    &.dark {
        color: $couleur-base-blanc;

        &:hover .btnLabel {
            color: $couleur-primaire-regular;
        }

        &.link {
            color: $couleur-base-noir;

            &:hover .btnLabel {
                color: $couleur-primaire-dark;
            }
        }
    }

    &.danger {
        color: $couleur-alerte-rouge;

        &:hover .btnLabel {
            color: $couleur-base-noir;
        }
    }
}

.icon {
    /* Need font-size and transform for vertical align */
    display: inline-block;
    font-size: 50%;
    line-height: 21px;
    position: relative;
    top: -1px;
    transform: scale(2);

    &.withText {
        padding-right: 8px;
        line-height: auto;

        &.iconRight {
            padding-left: 8px;
            padding-right: 0;

            &.fleche-right {
                padding-left: 0;
                padding-right: 8px;
            }
        }
    }

    &.iconOnly {
        line-height: 21px;
    }

    /* Custom Icon Style */
    &.fermer {
        padding-right: 5px;
    }

    &.delete {
        padding-right: 6px;
    }

    &.fleche,
    &.fleche-left {
        top: 1px;
    }

    &.fleche-right {
        transform: rotate(180deg) scale(2);
        top: 1px;
    }
}