@import '../../styles/variables.scss';

.NavMobile {

    display: flex;
    flex-direction: column;


    height: 100%;
    width: min(90vw, 400px);
    box-shadow: 1px 5px 10px black;

    .navPagesContainer {

        display: grid;
        flex: auto;
        overflow: auto;
        background-color: $couleur-primaire-regular;

        .navPageContainer {
            grid-area: 1 / 1;

            transition: all 0.4s;

            &.hideAnimation {
                opacity: 0;
                transition: all 0.4s;
            }

            .navPage {

                background-color: $couleur-primaire-regular;

                .backButton {
                    padding: 1rem;
                    border-bottom: 1px solid white;
                }

                .allButton {
                    padding: 1rem;
                    align-items: baseline;

                    em {
                        top: unset;
                    }
                }

                .navItem {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    color: white;
                    border-bottom: 1px solid white;

                    .navItemButton {
                        align-items: baseline;
                        color: #fff;
                        display: flex;
                        justify-content: space-between;
                        font-family: 'futura-pt', sans-serif;
                        font-weight: 500;
                        font-size: 16px;
                        padding: 1rem;
                        text-align: left;
                        text-decoration: none;
                        width: 100%;

                        :global(.icon) {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }

                }

            }



        }

    }


}