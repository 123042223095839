.fleche-right {
    transform: rotate(180deg);
}

.fleche-up {
    transform: rotate(90deg);
}

.fleche-down {
    transform: rotate(270deg);
}
