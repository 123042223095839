@import '../../styles/variables.scss';

.btnBuger {
    height: 25px;
    width: 21px;

    &:hover {
        span {
            background-color: $couleur-primaire-dark;
        }
    }

    span {
        span {
            background: white !important;
        }
    }
}

.menuBtn {
    background-color: transparent;
    cursor: pointer;
    height: 60px;
    position: relative;
    top: 0;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    width: 60px;

    b {
        margin-left: 24px;
    }

    &:focus,
    &:hover{
        :global(.btnLabel) {
            background-color: transparent;
        }
    }

    :global(.btnLabel) {
        background-color: transparent;
        display: block;
        height: 100%;
        position: relative;
        width: 30px;
        margin-left: 10px;
        top: 10px;

        &:focus,
        &:hover{
            background-color: transparent;
        }

        span {
            border-radius: 1px;
            display: block;
            height: 2px;
            left: 0;
            opacity: 1;
            position: absolute;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
            width: 100%;

            &:nth-child(1) {
                top: 10px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 18px;
            }

            &:nth-child(4) {
                top: 26px;
            }
        }
    }

    &.open {
        :global(.btnLabel) {
            
            span {
                &:nth-child(1) {
                    left: 50%;
                    top: 8px;
                    width: 0%;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    left: 50%;
                    top: 8px;
                    width: 0%;
                }
            }
        }
    }
}