@import '../../styles/variables.scss';



.headerWithPub {
    position: sticky;
    top: 0;

    background-color: white;
    z-index: 20;

    .pubMobile {
        overflow: hidden;
        max-height: 150px;
        transition: max-height 0.2s ease-in;

        &.hideWithDelay {
            max-height: 0;
            transition: max-height 1.5s ease-in-out;
            transition-delay: 3s;
        }

        &.hideNoDelay {
            transition: none;
            max-height: 0;
        }
    }

}

.barMnu {
    align-items: center;
    background-color: $couleur-primaire-regular;
    display: inline-grid;
    height: 60px;
    width: 100%;

    .menuButton {
        grid-area: 1/1;
        margin-left: 0;
    }

    .logoLink {
        grid-area: 1/1;
        justify-self: center;
        align-self: center;
        display: flex;

        a {
            display: flex;
            align-items: center;

            img {
                height: 40px;
                position: relative;
                top: -2px;
            }
        }

    }
}

.navContainer {
    position: fixed;
    overflow: auto;
    z-index: 100;
    box-shadow: 0 1px 5px 0 #005b5f;
    transform: translateX(-100%);

    &.show {
        transform: translateX(0);
        transition: transform 0.2s ease-out;
    }
}