@import '../../styles/variables.scss';

.me {
    align-items: center;
    background-color: $couleur-secondaire-regular;
    border-radius: 50%;
    color: $couleur-base-blanc;
    display: inline-flex;
    font-size: $font-size-xl;
    height: 45px;
    justify-content: center;
    justify-items: center;
    text-align: center;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    width: 45px;

    span {
        align-items: center;
        display: inline-flex;
        justify-items: center;
        text-align: center;
        text-decoration: none;
        justify-content: center;
    }

    &:focus,
    &:hover {
        background-color: $couleur-primaire-dark;
    }
}
