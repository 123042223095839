@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.me {
    @include bouton;

    background-color: $couleur-primaire-dark;
    border: 1px solid $couleur-primaire-dark;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    bottom: 10px;
    color: $couleur-base-blanc;
    transform: translateX(100%);
    position: fixed;
    right: 0;
    z-index: 1000;

    &:hover,
    &:focus {
        background-color: $couleur-primaire-regular;
        border-color: $couleur-primaire-regular;
        color: $couleur-base-blanc;
        text-decoration: none;

        span {
            @media #{$mq-medium-and-over} {
                text-decoration: underline;
            }
        }
    }

    &:focus {
        transform: translateX(0);
    }

    span {
        display: none;

        @media #{$mq-medium-and-over} {
            display: inline;
            scroll-margin-top: 115px;
        }
    }
}

.showScrollToTop {
    transform: translateX(0);

    @media print {
        display: none;
    }
}